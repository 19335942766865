import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { UserCompany } from '@app/models/user.company.model';
import { AuthService } from '@auth0/auth0-angular';
import {
  SidenavFooterConfig,
  SidenavHeaderConfig
} from '@config/sidenav.config';
import { environment } from '@environments/environment';
import { appName } from '@environments/shared';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';
import { FaviconsService } from '@zonar-ui/core';
import { FeatureToggleService } from '@zonar-ui/feature-toggle';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { DeviceDetectionService } from './services/device-detection/device-detection.service';
import { SelectedCompanyService } from './services/selected-company/selected-company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  loading = true;
  title = appName;

  currentCompany$ = new BehaviorSubject<UserCompany>({
    title: '',
    value: ''
  });
  sidenavHeader = SidenavHeaderConfig;
  sidenavFooter = SidenavFooterConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    footerOffset: false
  };

  constructor(
    private auth: AuthService,
    private datadog: DataDogService,
    private favicon: FaviconsService,
    private pendoService: PendoService,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private permissionsService: PermissionsService,
    private router: Router,
    public device: DeviceDetectionService,
    private selectedCompanyService: SelectedCompanyService,
    private _cdRef: ChangeDetectorRef,
    private gtcxMobileHelperService: GTCxMobileHelperService,
    private featureToggleService: FeatureToggleService
  ) {
    this.auth.isAuthenticated$
      .pipe(filter((isAuthenticated) => Boolean(isAuthenticated)))
      .subscribe(() => {
        this.datadog.startSessionReplayRecording();
      });

    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    // when we initialize pendo, some of the api calls require a zoner owner id, which can only be attached to the
    // request after the permissions are loaded. therefore, we must wait to initialize pendo until after the
    // permissions are loaded. ultimately, this logic should be handled in the pendo service itself as described here:
    // https://jira.zonarsystems.net/browse/ZTT-1015
    this.permissionsService
      .getIsPermissionsLoaded()
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(250),
        filter((val) => !!val)
      )
      .subscribe(() => {
        this.pendoService.initialize();
      });

    if (environment.region === 'NA') {
      this.zuiAnalytics.addGtmToDom();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && environment.region === 'NA') {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });
  }

  ngOnDestroy(): void {
    this.datadog.stopSessionReplayRecording();
  }

  switchCompany(incomingCompany: UserCompany): void {
    // This is needed until fix is applied to sidenav component.
    this.permissionsService
      .getCompanyMap()
      .pipe(
        take(1),
        map((companies) => {
          if (companies && companies[incomingCompany.value]) {
            // Setting Company context to use it on Zonar-Owner-Id for selecting right profile.
            this.permissionsService.setCurrentCompanyContext(
              companies[incomingCompany.value]
            );
          }
          this.selectedCompanyService.setCompanyIdFromSideNav(incomingCompany);
          this.featureToggleService.initializeDevCycle(incomingCompany.value);
          this.gtcxMobileHelperService.setCurrentCompany(incomingCompany.value);
        })
      )
      .subscribe();
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavParams.mobileOpened = event;
    this._cdRef.detectChanges();
  }
}
